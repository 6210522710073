<template>
  <EntityList
    :config="config"
    :columns="columns"
    :formatted-columns="formattedColumns"
    :filters="filters"
    :filter-vals.sync="filterVals"
    single-edit
    authority="Users_Customer"
    enable-disable-toggle
  />
</template>

<script>
import EntityList from '@/layouts/entity/EntityList.vue'
import storeModule from '../customersStoreModule'

export default {
  components: {
    EntityList,
  },
  data() {
    return {
      filterVals: [],
    }
  },
  computed: {
    config() {
      return {
        store: storeModule,
        module: 'customer',
        endpoint: 'customer',
        route: 'customers',
        title: {
          single: this.$i18n.t('Customer'),
          plural: this.$i18n.t('Customers'),
        },
      }
    },

    columns() {
      return [
        { key: 'id', label: '#', sortable: true },
        { key: 'participant.firstName', label: this.$i18n.t('First Name'), sortable: true },
        { key: 'participant.lastName', label: this.$i18n.t('Last Name'), sortable: true },
        { key: 'enabled', label: this.$i18n.t('enabled') },
        { key: 'actions', label: this.$i18n.t('actions') },
      ]
    },

    formattedColumns() {
      return [
        { key: 'enabled', type: 'boolean' },
      ]
    },

    filters() {
      return [
        {
          name: 'enabled',
          type: 'boolean',
          label: this.$i18n.t('enabled'),
          value: null,
        },
        {
          name: 'createdfrom',
          type: 'date',
          label: this.$i18n.t('Created From'),
          value: null,
          maxDateFieldIdx: '2',
          maxDate: new Date(),
        },
        {
          name: 'createdto',
          type: 'date',
          label: this.$i18n.t('Created To'),
          value: null,
          minDateFieldIdx: '1',
          maxDate: new Date(),
        },
      ]
    },
  },
}
</script>
